// Filename - ContactUs.jsx
import React from "react";

const answer5 = () => {
	return (
<pre><h3>{'because the no-store and max-age=0 directives require the client to request every page from the server\nso it never wants to cache data'}</h3></pre> 	

 );
};

export default answer5;

