// Filename - ContactUs.jsx
import React from "react";

const answer4 = () => {
	return (
<pre><h3>{'CloudFlare offers security DNS over https and tls, DDoS protection against DNS flood attack, and\nperformance fastest DNS resolution\nyes you can delegate so they will be authoritative for your zone and\n your host DNS will begin a recursive process until Cloudfalre nameservers response them with the IP of your domain'}</h3></pre>	
 );
};

export default answer4;

