// Filename - ContactUs.jsx
import React from "react";

const answer3= () => {
	return (

<pre><h3>{'check differences in the environment settings and also the logs  between running in the debugger mode versus querying the site'}</h3></pre> 

	);
};

export default answer3;

