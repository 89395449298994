// Filename - ContactUs.jsx
import React from "react";

const answer6 = () => {
	return (

 <pre><h3>{`use Workers based on User Agent 
export default {
  async fetch (request) {
const userAgent = request.headers.get('User-Agent') || ''; 
    if (userAgent.includes('MSIE 8.0')) {
      return Response.redirect('https://example.com', 301}`}</h3>i</pre>
	);
};

export default answer6;

