// Filename - ContactUs.jsx
import React from "react";

const answer2 = () => {
	return (
<pre><h3>{'Cloudflare uses anycast IP ranges so those IPs exist globally and they do not show where is the real location of your webserver'}</h3></pre> 	

);
};

export default answer2;

